<template>
  <div class="apartments-wrapper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="apikey_modal_delete_item"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteItemKey(entry.delete_id)"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="transport_car_modal_delete"
      :modal-header-title="$t('apartments.delete')"
      @confirmed="deleteTransportCarConfirm()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("apartments.delete confirm transport") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="transport_modal_delete_item"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteTransportAreaItem(item.id)"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="transport_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteTransportArea(entry.delete_id, entry.indexId)"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="apartment_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteApartment()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <div v-if="idEditing" class="row">
        <div class="col-12">
          <!-- Default box -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">ID</label>
                <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
              </div>
              <div class="row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.name") }}</label>
                <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entryNameUpdate }}</div>
                 <div class="col-xl-6 col-lg-4 col-sm-4 text-right">
                    <button v-if="idEditing" type="button" class="btn btn-danger m-r-10" @click="handleDeleteApartment()">
                      {{ $t('apartments.delete') }}
                    </button>
                  </div>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
      <form class="form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
        <div class="row">
          <div class="col-12">
            <div class="card card-primary card-outline card-outline-tabs">
              <div class="card-header p-0 border-bottom-0">
                <ul class="nav nav-tabs mt-2" id="custom-tabs-three-tab" role="tablist">
                  <li class="nav-item" @click="setUpdateForm(false, false)">
                    <a
                      class="nav-link" :class="{active: isActiveTabZero}" id="apartments-settings-tab" data-toggle="pill" href="#apartments-settings" role="tab" aria-controls="apartments-settings" aria-selected="true">
                      {{ $t("apartments.settings") }}
                    </a>
                  </li>
                  <li class="nav-item" @click="setUpdateForm(true, false)">
                    <a class="nav-link" id="apartments-api_key-tab" data-toggle="pill" href="#apartments-api_key" role="tab" aria-controls="apartments-api_key" aria-selected="false">
                      {{ $t("apartments.api_key") }}
                    </a>
                  </li>
                  <li class="nav-item" @click="setUpdateForm(false, false)">
                    <a class="nav-link" id="apartments-line_setting-tab" data-toggle="pill" href="#apartments-line_setting" role="tab" aria-controls="apartments-line_setting" aria-selected="false">
                      {{ $t("apartments.line_setting") }}
                    </a>
                  </li>
                  <!--<li class="nav-item" @click="setUpdateForm(false, false)">
                    <a class="nav-link" id="apartments-veritrans_setting-tab" data-toggle="pill" href="#apartments-veritrans_setting" role="tab" aria-controls="apartments-veritrans_setting" aria-selected="false">
                      {{ $t("apartments.veritrans_setting") }}
                    </a>
                  </li>-->
                  <li class="nav-item" @click="setUpdateForm(false, false)">
                    <a class="nav-link" :class="{active: isActiveTabFour}" id="apartments-pickup_dropoff_setting-tab" data-toggle="pill" href="#apartments-pickup_dropoff_setting" role="tab" aria-controls="apartments-pickup_dropoff_setting" aria-selected="false">
                      {{ $t("apartments.pickup_dropoff") }}
                    </a>
                  </li>
                  <li class="nav-item" @click="setUpdateForm(false, true)">
                    <a class="nav-link" :class="{active: isActiveTabFive}" id="apartments-pickup_area_setting-tab" data-toggle="pill" href="#apartments-pickup_area_setting" role="tab" aria-controls="apartments-pickup_area_setting" aria-selected="true">
                      {{ $t("apartments.pickup_area") }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="custom-tabs-three-tabContent">
<!--                  For Setting Tab-->
                  <div class="tab-pane fade show" :class="{active: isActiveTabZero}"  id="apartments-settings" role="tabpanel" aria-labelledby="apartments-settings-tab">
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.name") }} <span class="app-require">＊</span>
                      </label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-input
                          name="name"
                          type="string:128"
                          rules="required"
                          v-model="entry.name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.postal_code") }} <span class="app-require">＊</span></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-input
                          name="postal_code"
                          type="ja_postal_code"
                          rules="required|number_halfwidth"
                          placeholder="0000000"
                          v-model="entry.postal_code"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.prefecture") }} <span class="app-require">＊</span></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-select
                          name="prefecture"
                          rules="required"
                          :options-data="meta.prefectures"
                          v-model="entry.pref_id"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.address") }} <span class="app-require">＊</span></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-input
                          name="address"
                          type="string128"
                          rules="required"
                          v-model="entry.address"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.url") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-input
                          name="url"
                          type="string512"
                          rules="url"
                          v-model="entry.url"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.person_in_charge") }}
                        <span class="app-require">＊</span>
                      </label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-select-multi
                          name="admin_ids"
                          :options-data="meta.admins"
                          v-model="entry.admin_ids"
                          :rules="`required|select_multi_validate:${entry.admin_ids}`"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.note") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-text
                          name="note"
                          type="string1024"
                          v-model="entry.note"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.transport_service") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 gr-icheck">
                        <app-checkbox
                          :label="$t('apartments.status_transport_service')"
                          v-model="entry.status_transport_service"
                          class="m-r-20"
                          @input="handlerChangeStatusTransportService"
                        />
                      </div>
                    </div>
                    <div class="card-footer row justify-content-end">
                      <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                        {{ $t('common.cancel') }}
                      </button>
                      <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary" type="submit">
                        {{  $t('common.save') }}
                      </button>
                      <button v-else :disabled="!valid && validated" class="btn btn-primary" type="submit">
                        {{  $t('common.create') }}
                      </button>
                    </div>
                  </div>
<!--                  For api key Tab-->
                  <div class="tab-pane fade" id="apartments-api_key" role="tabpanel" aria-labelledby="apartments-api_key-tab">
                    <h3 class="heading-title1">APIキー発行</h3>

                    <div v-if="!idEditing">※マンションの登録後に設定してください。</div>
                    <div v-else>
                        <div class="row">
                          <div class="col-xl-10 col-lg-12 col-sm-12">
                            <div class="table-respnsive w-100">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                <th class="text-center cell-fit-content" style="width: 5%;">{{ $t("apartments.id_en") }}</th>
                                <th class="text-center cell-fit-content" style="width: 35%;">{{ $t("apartments.name_en") }}</th>
                                <th class="text-center cell-fit-content" style="width: 35%;">{{ $t("apartments.token_en") }}</th>
                                <th class="text-center cell-fit-content" style="width: 20%;">{{ $t("apartments.edit_en") }}</th>
                                </thead>
                                <tbody>
                                <tr :key="apiKeyIndex" v-for="(entry, apiKeyIndex) in entry.apiKeys">
                                  <slot name="body-cell-no" :row="entry">
                                    <td class="app-align-middle text-center" style="width: 5%;">
                                      {{ entry.id }}
                                    </td>
                                  </slot>

                                  <td class="app-align-middle" style="width: 35%;">
                                    <validation-observer
                                      v-if="isUpdateApiKey"
                                      :ref="`form_update_api_key_${apiKeyIndex}`"
                                      v-slot="{ passes, valid, validated }"
                                    >
                                      <div class="form-group row m-b-5">
                                        <div class="col-sm-11">
                                          <app-input
                                            name="name_en"
                                            type="string128"
                                            rules="required"
                                            v-model="entry.name"
                                          />
                                        </div>
                                        <span class="app-require p-t-10">＊</span>
                                      </div>
                                    </validation-observer>
                                  </td>

                                  <td class="app-align-middle" style="width: 35%;">
                                    <p v-model="entry.token" style="margin-top: 10px !important; word-break: break-word;">{{ entry.token }}</p>
                                  </td>
                                  <td class="app-align-middle" style="width: 20%;">
                                    <div class="row justify-content-center gr-button">
                                      <button style="min-width: 80px !important;" type="button" class="btn btn-primary" @click="handleUpdateApiKey(entry, `form_update_api_key_${apiKeyIndex}`)">
                                        {{  $t('apartments.update') }}
                                      </button>
                                      <button style="min-width: 80px !important;" v-if="entry.id" type="button" class="btn btn-danger" @click="handleDeleteApiKey(entry.id, apiKeyIndex)">
                                        {{ $t('apartments.delete') }}
                                      </button>
                                      <button style="min-width: 80px !important;" v-else type="button" class="btn btn-danger" @click="handleDeleteItemKey(apiKeyIndex)">
                                        {{ $t('apartments.delete') }}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-if="!entry.apiKeys.length">
                                  <td colspan="100">
                                    <span v-if="loading">
                                      {{ $t("common.loading") }}
                                    </span>
                                    <span v-else>
                                      {{ $t("common.list empty") }}
                                    </span>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                          </div>
                          </div>
                        </div>
                        <div class="card-footer row justify-content-end">
                          <button type="button" class="btn btn-primary" @click="handleAddApiKey">
                            {{  $t('apartments.add_api_key') }}
                          </button>
                        </div>
                    </div>
                  </div>
<!--                  For Line Tab-->
                  <div class="tab-pane fade" id="apartments-line_setting" role="tabpanel" aria-labelledby="apartments-line_setting-tab">
                    <h3 class="heading-title1">LINE 設定</h3>

                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.line_token") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 apartment-align">
                        <app-input
                          name="line_token"
                          type="string:172"
                          v-model="entry.line_token"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.line_webhook_url") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 apartment-align">
                        <app-input
                          name="line_webhook_url"
                          type="string512"
                          rules="url"
                          v-model="entry.line_webhook_url"
                          disabled="true"
                          id="line_webhook_url"
                        />
                      </div>
                      <div class="col-xl-1 col-lg-1 col-sm-1" v-if="idEditing">
                        <img src="/images/copy-icon.png"  @click="copy">
                      </div>
                    </div>
                    <div class="form-group row" @input="changeUrlLineCode(entry.line_basic_id)">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.line_basic_id") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 apartment-align">
                        <app-input
                          name="basic_id"
                          type="string:24"
                          v-model="entry.line_basic_id"
                        />
                      </div>
                    </div>
                    <div class="form-group row" v-if="idEditing && entry.line_basic_id && entry.line_basic_id.length > 4">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.line_qrcode") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6" v-if="urlLineCode !== undefined">
                        <img class="img_qrcode" :src="urlLineCode"/>
                      </div>
                    </div>
                    <div class="card-footer row justify-content-end">
                      <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                        {{ $t('common.cancel') }}
                      </button>
                      <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary" type="submit">
                        {{  $t('common.save') }}
                      </button>
                      <button v-else :disabled="!valid && validated" class="btn btn-primary" type="submit">
                        {{  $t('common.create') }}
                      </button>
                    </div>
                  </div>
<!--                  For Veritrans Tab-->
                  <!--<div class="tab-pane fade" id="apartments-veritrans_setting" role="tabpanel" aria-labelledby="apartments-veritrans_setting-tab">
                    <h3 class="heading-title1">VeriTrans 設定</h3>

                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.payment_vt_token") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 apartment-align">
                        <app-input
                          name="payment_vt_token"
                          type="string128"
                          v-model="entry.payment_vt_token"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.payment_vt_ccid") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 apartment-align">
                        <app-input
                          name="payment_vt_ccid"
                          type="string128"
                          v-model="entry.payment_vt_ccid"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.payment_vt_secretkey") }}</label>
                      <div class="col-xl-5 col-lg-6 col-sm-6 apartment-align">
                        <app-input
                          name="payment_vt_secretkey"
                          type="string128"
                          v-model="entry.payment_vt_secretkey"
                        />
                      </div>
                    </div>
                    <div class="card-footer row justify-content-end">
                      <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                        {{ $t('common.cancel') }}
                      </button>
                      <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary" type="submit">
                        {{  $t('common.save') }}
                      </button>
                      <button v-else :disabled="!valid && validated" class="btn btn-primary" type="submit">
                        {{  $t('common.create') }}
                      </button>
                    </div>
                  </div>-->

<!--                  For Pickup dropff Tab-->
                  <div class="tab-pane fade show" :class="{active: isActiveTabFour}" id="apartments-pickup_dropoff_setting" role="tabpanel" aria-labelledby="apartments-pickup_dropoff_setting-tab">
                    <h3 class="heading-title1">送迎サービス設定</h3>

                    <div v-if="!idEditing">※マンションの登録後に設定してください。</div>
                    <div v-else>
                      <div class="row">
                        <div class="col-xl-10 col-lg-12 col-sm-12 app-table-container">
                          <div class="table-respnsive w-100 mb-3">
                            <table class="table table-bordered app-table-list">
                              <thead class="thead-light">
                              <th class="text-center cell-fit-content" style="width: 15%;">{{ $t("apartments.name_shuttle_car") }}</th>
                              <th class="text-center cell-fit-content" style="width: 20%;">{{ $t("apartments.business_day") }}</th>
                              <th class="text-center cell-fit-content" style="width: 20%;">{{ $t("apartments.business_hour") }}</th>
                              <th class="text-center cell-fit-content" style="width: 10%;">{{ $t("apartments.time_unit") }}</th>
                              <th class="text-center cell-fit-content" style="width: 10%;">{{ $t("apartments.capacity") }}</th>
                              <th class="text-center cell-fit-content" style="width: 25%;">{{ $t("apartments.operation") }}</th>
                              </thead>
                              <tbody>
                              <tr :key="ApiPickUpIndex" v-for="(entry, ApiPickUpIndex) in entry.transportCar">
                                <slot name="body-cell-no" :row="entry">
                                  <td class="app-align-middle min-w-100 text-left" style="width: 15%;">
                                    <p class="app-table-p app-cell-tooltip mb-0" @mouseover="showTooltip()" :data-original-title="entry.name">{{ entry.name }}</p>
                                  </td>
                                </slot>

                                <td class="app-align-middle min-w-100 text-center" style="width: 20%;">
                                  {{ getWorkDays(entry.is_open_sunday, entry.is_open_monday, entry.is_open_tuesday, entry.is_open_wednesday, entry.is_open_thursday,
                                  entry.is_open_friday, entry.is_open_saturday) }}
                                </td>

                                <td class="app-align-middle min-w-100 text-center" style="width: 20%;">
                                  {{ getOpeningTime(entry.opening_time) }} - {{getOpeningTime(entry.closing_time)}}
                                </td>

                                <td class="app-align-middle min-w-100 text-center" style="width: 10%;">
                                  {{ entry.unit_time}} 分
                                </td>

                                <td class="app-align-middle min-w-100 text-center" style="width: 10%;">
                                  {{ entry.unit_capacity }}
                                </td>

                                <td class="app-align-middle" style="width: 20%;">
                                  <div class="row justify-content-center gr-button">
                                    <button style="min-width: 80px !important;" type="button" class="btn btn-primary" @click="handleBtnUpdateClick(entry.id)">
                                      {{  $t('common.edit') }}
                                    </button>
                                    <button style="min-width: 80px !important;" v-if="entry.id" type="button" class="btn btn-danger" @click="deleteTransportCar(entry.id)">
                                      {{ $t('apartments.delete') }}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr v-if="!entry.transportCar.length">
                                <td colspan="100">
                                  <span v-if="loading">
                                    {{ $t("common.loading") }}
                                  </span>
                                  <span v-else>
                                    {{ $t("common.list empty") }}
                                  </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                      <div class="card-footer row justify-content-end">
                        <button type="button" class="btn btn-warning" @click="handleBtnCreateClick()">
                          {{  $t('common.create') }}
                        </button>
                      </div>
                    </div>
                  </div>

<!--                  For Pickup area Tab-->
                  <div class="tab-pane fade" id="apartments-pickup_area_setting" role="tabpanel" aria-labelledby="apartments-api_key-tab">
                    <h3 class="heading-title1">送迎対象のエリア（郵便番号ベース）</h3>

                    <div v-if="!idEditing">
                      <div class="row">
                        <div class="col-xl-10 col-lg-12 col-sm-12 app-table-container">
                          <div class="table-respnsive w-100 mb-3">
                            <table class="table table-bordered app-table-list">
                              <thead class="thead-light">
                              <th class="text-center cell-fit-content" style="width: 5%;">{{ $t("apartments.id_en") }}</th>
                              <th class="text-center cell-fit-content" style="width: 30%;">{{ $t("apartments.postal_code") }}</th>
                              <th class="text-center cell-fit-content" style="width: 30%;">{{ $t("apartments.address") }}</th>
                              <th class="text-center cell-fit-content" style="width: 20%;">{{ $t("apartments.operation") }}</th>
                              </thead>
                              <tbody>
                              <tr :key="index" v-for="(item, index) in entry.transportArea">
                                <slot name="body-cell-no" :row="item">
                                  <td class="app-align-middle text-center" style="width: 5%;"></td>
                                </slot>

                                <td class="app-align-middle" style="width: 30%;" @input="change(index)">
                                  <validation-observer
                                    :ref="`form_update_api_key_${index}`"
                                    v-slot="{ passes, valid, validated }"
                                  >
                                  <div class="form-group row m-b-5">
                                    <div class="col-sm-11">
                                      <app-input
                                        name="postal_code"
                                        type="ja_postal_code"
                                        rules="required|number_halfwidth"
                                        placeholder="0000000"
                                        v-model="item.zipcode_id"
                                      />
                                      </div>
                                      <span class="app-require p-t-10">＊</span>
                                    </div>
                                  </validation-observer>
                                </td>

                                <td class="app-align-middle" style="width: 30%;">
                                  {{ item.address }}
                                </td>

                                <td class="app-align-middle" style="width: 30%;">
                                  <div class="row justify-content-center gr-button">
                                    <button style="min-width: 80px !important;" type="button" class="btn btn-danger" @click="handleDeleteItem(index)">
                                      {{ $t('apartments.delete') }}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr v-if="!entry.transportArea.length">
                                <td colspan="100">
                                  <span v-if="loading">
                                    {{ $t("common.loading") }}
                                  </span>
                                  <span v-else>
                                    {{ $t("common.list empty") }}
                                  </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer row justify-content-end pb-0">
                        <button type="button" class="btn btn-primary" @click="handleAddTransportArea">
                          {{  $t('apartments.add_api_key') }}
                        </button>
                      </div>

                      <div class="card-footer row justify-content-end">
                        <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                          {{ $t('common.cancel') }}
                        </button>
                        <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary" type="submit">
                          {{  $t('common.save') }}
                        </button>
                        <button v-else :disabled="!valid && validated" class="btn btn-primary" type="submit">
                          {{  $t('common.create') }}
                        </button>
                      </div>
                    </div>

                    <div v-else>
                      <div class="row">
                        <div class="col-xl-10 col-lg-12 col-sm-12">
                          <div class="table-respnsive w-100">
                            <table class="table table-bordered">
                              <thead class="thead-light">
                                <th class="text-center cell-fit-content" style="width: 5%;">{{ $t("apartments.id_en")}}</th>
                                <th class="text-center cell-fit-content" style="width: 30%;">{{$t("apartments.postal_code")}}</th>
                                <th class="text-center cell-fit-content" style="width: 30%;">{{ $t("apartments.address")}}</th>
                                <th class="text-center cell-fit-content" style="width: 20%;">{{ $t("apartments.operation")}}</th>
                              </thead>
                              <tbody>
                              <tr :key="index" v-for="(item, index) in entry.transportArea">
                                <slot name="body-cell-no" :row="item">
                                  <td class="app-align-middle text-center" style="width: 5%;">
                                    {{ item ? item.id : ""}}
                                  </td>
                                </slot>

                                <td class="app-align-middle" style="width: 30%;" @input="changeInput(index)">
                                  <validation-observer
                                    v-if="isUpdateTransportArea"
                                    :ref="`form_update_transport_area_${index}`"
                                    v-slot="{ passes, valid, validated }"
                                  >
                                  <div class="form-group row m-b-5">
                                    <div class="col-sm-11" v-if="item.zipcode">
                                      <app-input
                                        name="postal_code"
                                        type="ja_postal_code"
                                        rules="required|number_halfwidth"
                                        placeholder="0000000"
                                        v-model="item.zipcode.postal_code"
                                      />
                                    </div>
                                    <div class="col-sm-11" v-else>
                                      <app-input
                                        name="postal_code"
                                        type="ja_postal_code"
                                        rules="required|number_halfwidth"
                                        placeholder="0000000"
                                        v-model="item.postal_code"
                                      />
                                    </div>
                                    <span class="app-require p-t-10">＊</span>
                                    </div>
                                  </validation-observer>
                                </td>

                                <td class="app-align-middle" style="width: 30%;" v-if="item != undefined && item.address != undefined ">
                                  {{item.address}}
                                </td>

                                <td class="app-align-middle" style="width: 30%;" v-else>
                                  {{checkAddressZipCode(item) }}
                                </td>

                                <td class="app-align-middle" style="width: 30%;">
                                  <div class="row justify-content-center gr-button">
                                    <button style="min-width: 80px !important;" v-if="entry.id" type="button" class="btn btn-primary"
                                            @click="handleUpdateTransportArea(item, index,`form_update_transport_area_${index}`)">
                                      {{ $t('apartments.update') }}
                                    </button>
                                    <button style="min-width: 80px !important;" v-if="item.id" type="button" class="btn btn-danger" @click="handleDeleteTransportArea(item.id, index)">
                                        {{ $t('apartments.delete') }}
                                      </button>
                                    <button style="min-width: 80px !important;" v-else type="button" class="btn btn-danger" @click="deleteTransportAreaItem(index)">
                                        {{ $t('apartments.delete') }}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr v-if="!entry.transportArea.length">
                                <td colspan="100">
                                  <span v-if="loading">
                                    {{ $t("common.loading") }}
                                  </span>
                                  <span v-else>
                                    {{ $t("common.list empty") }}
                                  </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer row justify-content-end">
                        <button type="button" class="btn btn-primary" @click="handleAddOrUpdateTransportArea()">
                          {{ $t('apartments.add_api_key') }}
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { createHmac } from 'crypto';
import AppCheckbox from "../../../components/forms/AppCheckbox";
import {__getLocalStorage, __removeLocalStorage, __setLocalStorage} from "@utils";
import {MIN_LENGTH_QR_CODE_LINE} from '../../../constants/api';

export default {
  name: "FormPage",

  components: {
    AppCheckbox
  },

  data() {
    const id = this.$route.params.id
    const user = this.$store.getters["auth/getUser"]
    return {
      entry: {
        apiKeys: [],
        admin_ids : [user.id],
        transportCar: [],
        transportArea: []
      },
      apiKeyId: null,
      apiKeyIndex: null,
      transportId: null,
      transportCarId: null,
      apartment_id: null,
      meta: {
        prefectures: [],
        admins: [],
      },
      idEditing: id,
      entryNameUpdate: "",
      isUpdateApiKey: false,
      isUpdateTransportArea: false,
      isActiveTabZero: true,
      isActiveTabFour: false,
      isActiveTabFive: false,
      address: "",
      delete_id: null,
      indexId: null,
      index: null,
      arrayTransportArea: [],
      checkCarIsExists: false,
      loading: false,
      urlLineCode: undefined
    };
  },

  watch: {
    'entry.postal_code': function(value, oldValue) {
      if (value != oldValue && value.length == 7 && oldValue != undefined) {
        this.getByPostalCode(value)
      }
    }
  },
   mounted: function () {
    $(".tooltip").tooltip("hide");
    this.$request.get(this.ENDPOINT.UI_HELPER_APARTMENT).then(res => {
      let prefectures = _.cloneDeep(res.data.prefectures);
      prefectures.unshift({
        id: "",
        name: this.$t('common.choose'),
      });
      this.meta = {
        prefectures: prefectures,
        admins: res.data.admins
      }
    })
    if (this.idEditing) {
      this.fetchListData()
    }
    if (this.$route.params.actionFormTransportCar === true) {
      this.isActiveTabZero = false
      this.isActiveTabFour = true
    }
  },

  methods: {
    changeUrlLineCode(line_basic_id) {
      if (line_basic_id.length > MIN_LENGTH_QR_CODE_LINE && line_basic_id.substring(0, 1) == "@") {
        this.urlLineCode = "https://qr-official.line.me/sid/L/" + line_basic_id.slice(1) + ".png";
      }
    },
    fetchListData() {
      this.$request.get(this.ENDPOINT.APARTMENT_EDIT(this.idEditing)).then(res => {
        if (res.hasErrors()) {
          if (res.status === 'error'){
            this.__noticeError(this.$t(res.data.error_msg));
          }
          this.redirectDefaultValue()
        } else {
          this.entry = res.data.data
          this.entryNameUpdate = this.entry.name;
          this.urlLineCode = "https://qr-official.line.me/sid/L/" + this.entry.line_basic_id.slice(1) + ".png";
        }

      })
    },
    change(index) {
      let value = this.entry.transportArea[index].zipcode_id
      if (value.length === 7) {
        this.getAddressByZipCode(value, index)
      }
    },
    changeInput(index) {
      let value = this.entry.transportArea[index].zipcode ?
        this.entry.transportArea[index].zipcode.postal_code : this.entry.transportArea[index].postal_code
      if (value.length === 7) {
        this.getAddressByPostalCode(value, index)
      }
    },
    handleTestChange(e) {
      console.log(e.target.value);
    },
    confirmCancel() {
      if (this.__getLocalStorage('apartment_search_url')) {
        window.location.href = this.__getLocalStorage('apartment_search_url');
        this.__removeLocalStorage('apartment_search_url');
      } else {
        this.redirectDefaultValue()
      }
    },
    getByPostalCode(postalCode) {
      this.$request.get(this.ENDPOINT.ADDRESS_BY_ZIPCODE(postalCode)).then(res => {
        var data = res.data;
        if (data.province_id != undefined) {
          if (data.area == '以下に掲載がない場合') {
            data.area = '';
          }
          this.entry.address = data.city + (data.area != null ? data.area : "");
          this.entry.pref_id = data.province_id;
        } else {
          this.__noticeError(this.$t('messages.postal_code_invalid'));
        }
      })
    },
    getAddressByZipCode(postalCode, index) {
      this.$request.get(this.ENDPOINT.ADDRESS_BY_ZIPCODE(postalCode)).then(res => {
        var data = res.data;
        if (data.province_id != undefined) {
          if (data.area == '以下に掲載がない場合') {
            data.area = '';
          }
          this.entry.transportArea[index].address = data ? data.province + data.city + (data.area != null ? data.area : "") : "";
        } else {
          this.__noticeError(this.$t('messages.postal_code_invalid'));
        }
      })
    },
    getAddressByPostalCode(postalCode, index) {
      this.$request.get(this.ENDPOINT.ADDRESS_BY_ZIPCODE(postalCode)).then(res => {
        var data = res.data;
        if (data.province_id != undefined) {
          if (data.area == '以下に掲載がない場合') {
            data.area = '';
          }
          this.entry.transportArea[index].address = data ? data.province + data.city + (data.area != null ? data.area : "") : "";
          this.$forceUpdate();
        } else {
          this.__noticeError(this.$t('messages.postal_code_invalid'));
        }
      })
    },
    async createOrUpdate() {
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        params['data_updated_at'] = params['updated_at'] ? params['updated_at'] : params['created_at_datetime']
        delete params.transportArea
        delete params.transportCar
        delete params.apiKeys
        res = await this.$request.patch(this.ENDPOINT.APARTMENT_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.APARTMENT_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {

        if (typeof (res.data) == 'undefined') {
          this.__noticeError(this.$t('messages.account_deleted'));
          this.logoutAction()
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        }
        if (res.status === 'error') {
          await this.redirectDefaultValue()
        }
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },
    async redirectDefaultValue() {
      await this.$router.push({name: this.ROUTES.ADMIN.APARTMENT_LIST, query: {'filters.pref_id.equal': 'all'}});
    },
    handleAddApiKey() {
      let newApiKey = {
        name: '',
        token: this.generateToken(),
        apartment_id: this.idEditing
      };

      this.entry.apiKeys.push(newApiKey);
    },
    handleAddTransportArea() {
      let newTransportArea = {
        zipcode_id: "",
        apartment_id: "",
        address: "",
        zipcode: []
      };
      this.entry.transportArea.push(newTransportArea);
    },
    handleAddOrUpdateTransportArea() {
      let newTransportArea = {
        zipcode_id: "",
        apartment_id: this.idEditing,
        address: "",
        zipcode: []
      };
      this.entry.transportArea.push(newTransportArea);
    },
    async handleUpdateApiKey(apiKey, refElem) {
      var res = null;
      let params = _.cloneDeep(apiKey);
      let msg = "";
      const isValid = await this.$refs[refElem][0].validate();
      if (!isValid) {
      } else {
        if (apiKey.id) {
          res = await this.$request.patch(this.ENDPOINT.APARTMENT_API_KEY_EDIT(apiKey.id), params);
          msg = this.$t("common.msg update ok")
        } else {
          res = await this.$request.post(this.ENDPOINT.APARTMENT_API_KEY_CREATE, params);
          msg = this.$t("common.msg create ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        } else {
          this.__noticeSuccess(msg);
          let apiKey = res.data.data;
          var index = _.findIndex(this.entry.apiKeys, {token: apiKey.token});
          this.entry.apiKeys.splice(index, 1, apiKey);
        }
      }
    },

    async handleUpdateTransportArea(item, index, refElem) {
      var res = null;
      let params = _.cloneDeep(item);
      let msg = "";

      const isValid = await this.$refs[refElem][0].validate();
      if (!isValid) {
      } else {
        if (item.id) {
          params['data_updated_at'] = params['updated_at'] ? params['updated_at'] : params['created_at']
          res = await this.$request.patch(this.ENDPOINT.APARTMENT_TRANSPORT_AREA_EDIT(item.id), params);
          msg = this.$t("common.msg update ok")
        } else {
          if (item.zipcode.postal_code) {
            res = await this.$request.post(this.ENDPOINT.APARTMENT_TRANSPORT_AREA_CREATE,
              {postal_code: item.zipcode.postal_code, apartment_id: this.idEditing});
            msg = this.$t("common.msg create ok")
          }
        }
        if (res) {
          if (res.hasErrors()) {
            this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.__noticeSuccess(msg);
            let area = res.data.data;
            this.entry.transportArea.splice(index, 1, area);
          }
        }
      }
    },

    async deleteApiKey() {
      const res = await this.$request.delete(this.ENDPOINT.APARTMENT_API_KEY_DELETE(this.apiKeyId));
      if (!res.hasErrors()) {
        this.entry.apiKeys.splice(this.apiKeyIndex, 1)
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        this.$refs.apikey_modal_delete.hide();

        this.$refs.form_create_entry.reset();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      }
    },

    async deleteTransportArea(delete_id) {
      if (delete_id != undefined) {
        const res = await this.$request.delete(this.ENDPOINT.APARTMENT_TRANSPORT_AREA_DELETE(delete_id));
        if (!res.hasErrors()) {
          this.entry.transportArea.splice(this.index, 1)
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          this.$refs.transport_modal_delete.hide();

          // Refresh form validate
          this.$refs.form_create_entry.reset();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.transport_modal_delete.hide();
        }
      } else {
        this.entry.transportArea.splice(indexId, 1);
        this.$refs.transport_modal_delete.hide();

        // Refresh form validate
        this.$refs.form_create_entry.reset();
      }
    },
    deleteTransportAreaItem(id) {
      this.entry.transportArea.splice(id, 1);
      this.$refs.transport_modal_delete_item.hide();

      // Refresh form validate
      this.$refs.form_create_entry.reset();
    },
    handleDeleteApiKey(apiKeyId, apiKeyIndex) {
      this.apiKeyId = apiKeyId;
      this.apiKeyIndex = apiKeyIndex;
      this.$refs.apikey_modal_delete.show();
    },
    deleteItemKey(delete_id) {
      this.entry.apiKeys.splice(delete_id, 1)
      this.$refs.apikey_modal_delete_item.hide();
    },
    handleDeleteItemKey(index) {
      this.entry.apiKeys.splice(index, 1);

      // Refresh form validate
      this.$refs.form_create_entry.reset();
    },
    handleDeleteTransportArea(id, index) {
      this.entry.delete_id = id;
      this.entry.indexId = index;
      this.index = index;
      if (id) { // Already has data in db
        this.$refs.transport_modal_delete.show();
      } else {
        this.deleteTransportArea(this.entry.delete_id, this.entry.indexId);
      }
    },
    handleDeleteItem(index) {
      this.entry.transportArea.splice(index, 1);

      // Refresh form validate
      this.$refs.form_create_entry.reset();
    },
    handleDeleteApartment() {
      this.$refs.apartment_modal_delete.show();
    },
    async deleteApartment() {
      const res = await this.$request.delete(this.ENDPOINT.APARTMENT_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.apartment_modal_delete.hide();
        if (res.status === 'error') {
          await this.redirectDefaultValue()
        }
      }
    },
    generateToken() {
      const secret = Math.random().toString(36).substring(2, 22)
      const hash = createHmac('sha256', secret).digest('hex');

      return hash;
    },
    setUpdateForm(apiKey, transportArea) {
      this.isUpdateApiKey = apiKey;
      this.isUpdateTransportArea = transportArea;
    },
    handlerChangeStatusTransportService(checked) {
      this.status_transport_service = checked
    },
    getOpeningTime(openingTime)
    {
      let openingTimeFirst = openingTime.substring(0,2);
      let openingTimeSecond = openingTime.substring(2);

      return openingTimeFirst+':'+openingTimeSecond
    },
    showTooltip() {
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
    },

    getWorkDays(isOpenSunday, isOpenMonday, isOpenTuesday, isOpenWednesday, isOpenThursday, isOpenFriday, isOpenSaturday) {
      let workdays = '';
      if (isOpenSunday) workdays = workdays + this.$t('book-masters.sunday') + '/';
      if (isOpenMonday) workdays = workdays + this.$t('book-masters.monday') + '/';
      if (isOpenTuesday) workdays = workdays + this.$t('book-masters.tuesday') + '/';
      if (isOpenWednesday) workdays = workdays + this.$t('book-masters.wednesday') + '/';
      if (isOpenThursday) workdays = workdays + this.$t('book-masters.thursday') + '/';
      if (isOpenFriday) workdays = workdays + this.$t('book-masters.friday') + '/';
      if (isOpenSaturday) workdays = workdays + this.$t('book-masters.saturday') + '/';

      return workdays.slice(0,-1);
    },
    handleBtnCreateClick() {
      this.__setLocalStorage('is_from_apartment_page', true);
      let apartment_id = this.$route.fullPath.split('/')[3];
      this.$request.get(this.ENDPOINT.UI_HELPER_APARTMENTS(apartment_id)).then(res => {
        let apartmentName = res.data.apartment[0].name
        this.__setLocalStorage('apartmentName', apartmentName);
        this.__setLocalStorage('apartment_id', apartment_id);
        this.$router.push({name: this.ROUTES.ADMIN.TRANSPORTS_CREATE, params: {apartmentName: apartmentName, apartment_id: apartment_id}})
      })
    },
    async handleBtnUpdateClick(id) {
      this.__setLocalStorage('is_from_apartment_page', true);
      const res = await this.$request.get(this.ENDPOINT.UI_HELPER_TRANSPORTS_CAR(id));
      this.checkCarIsExists = res.data.transport.length
      if (this.checkCarIsExists == 0) {
        this.__noticeError(this.$t('common.error'));
        this.fetchListData()
      } else {
        let apartment_id = this.$route.fullPath.split('/')[3];
        this.$request.get(this.ENDPOINT.UI_HELPER_APARTMENTS(apartment_id)).then(res => {
        let apartmentName = res.data.apartment[0].name
        this.__setLocalStorage('apartmentName', apartmentName);
        this.__setLocalStorage('apartment_id', apartment_id);
        this.$router.push({name: this.ROUTES.ADMIN.TRANSPORTS_EDIT, params: {id: id, apartmentName: apartmentName, apartment_id: apartment_id}})
      })
      }
    },
    deleteTransportCar(id) {
      this.transportCarId = id;
      this.$refs.transport_car_modal_delete.show();
    },
    async deleteTransportCarConfirm(){
      const res = await this.$request.delete(this.ENDPOINT.TRANSPORTS_DELETE(this.transportCarId));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        this.$refs.transport_car_modal_delete.hide();
        this.fetchListData()
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.transport_car_modal_delete.hide();
      }
    },
    checkAddressZipCode(arrayItem) {
      if(arrayItem.zipcode !== undefined) {
        if (arrayItem.zipcode.province_id !== undefined) {
          if (arrayItem.zipcode.area == '以下に掲載がない場合') {
            arrayItem.zipcode.area = '';
          }
        }
        return arrayItem.zipcode['province'].name + arrayItem.zipcode.city + (arrayItem.zipcode.area != null ? arrayItem.zipcode.area : "")
    } else {
        if (arrayItem.province_id !== undefined) {
          if (arrayItem.area == '以下に掲載がない場合') {
            arrayItem.area = '';
          }
        }
        return arrayItem.province + arrayItem.city + (arrayItem.area != null ? arrayItem.area : "")
      }
    },
    copy() {
      var copyText = document.getElementById("line_webhook_url");
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.value);
      alert("Copied webhook url: " + copyText.value);
    }
  },
};
</script>
<style>
  .img_qrcode {
    width: 240px;
    height: 240px;
    margin-left: -20px
  }
</style>
